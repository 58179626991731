import { default as about_45us71631P0xkhMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/about-us.vue?macro=true";
import { default as indexd4zF6pPbmWMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/index.vue?macro=true";
import { default as _91id_93VakHo7WuGuMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/[id].vue?macro=true";
import { default as addMSN2LSPB53Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/add.vue?macro=true";
import { default as index1hGeMIbunhMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/index.vue?macro=true";
import { default as ordersAGKFQtCiMTMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/orders.vue?macro=true";
import { default as change_45password3BMwW1Rj5oMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/settings/change-password.vue?macro=true";
import { default as indexGLHdBCEZT7Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/settings/index.vue?macro=true";
import { default as filtersNvwGOQfloJMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/filters.vue?macro=true";
import { default as forgot_45passwordbGd9IuBjSVMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/forgot-password.vue?macro=true";
import { default as index6fKfFk4K9HMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/index.vue?macro=true";
import { default as loginbvAlbGdtpbMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/login.vue?macro=true";
import { default as carDBPCfEuE2fMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/car.vue?macro=true";
import { default as editBMiqp3PfKjMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/edit.vue?macro=true";
import { default as indexugWTDakyfoMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/index.vue?macro=true";
import { default as confirm_45requestVO7S2Uf000Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[request-offer-id]/confirm-request.vue?macro=true";
import { default as index0SsVg0OCNrMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[request-offer-id]/index.vue?macro=true";
import { default as send_45reviewlZFIlM1866Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/send-review.vue?macro=true";
import { default as index2B2rlRDK35Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/index.vue?macro=true";
import { default as change_45aboutj02e60aVhUMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-about.vue?macro=true";
import { default as change_45address5ftmqPumQoMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-address.vue?macro=true";
import { default as change_45emailCzZZ406sWMMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-email.vue?macro=true";
import { default as change_45nameSajP3f4iDVMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-name.vue?macro=true";
import { default as change_45phoneF0BaOtwgiwMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-phone.vue?macro=true";
import { default as change_45websiteDmHkMMkkyEMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-website.vue?macro=true";
import { default as changeCcTL14ZU4DMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change.vue?macro=true";
import { default as _91delivery_93RHoI96gC67Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-delivery/[delivery].vue?macro=true";
import { default as createsmpsW5GEA1Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-delivery/create.vue?macro=true";
import { default as _91payment_939LfOw7Flz8Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-payment-method/[payment].vue?macro=true";
import { default as createvO6TBGABvqMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-payment-method/create.vue?macro=true";
import { default as _91delivery_93C9K1AOgiePMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/delivery/[delivery].vue?macro=true";
import { default as indexQdJRREzt83Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/index.vue?macro=true";
import { default as indextXbyPpNHgJMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[seller-request-id]/index.vue?macro=true";
import { default as send_45offerPbFE8Omgl1Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[seller-request-id]/send-offer.vue?macro=true";
import { default as send_45reviewcQoU1pOmOiMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[seller-request-id]/send-review.vue?macro=true";
import { default as index2wsHnC3NQSMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/index.vue?macro=true";
import { default as _91payment_93mcHzIJEeQRMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/payment-method/[payment].vue?macro=true";
import { default as responseEibv5v2ENSMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/response.vue?macro=true";
import { default as settings0I2jNIFcEJMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/settings.vue?macro=true";
import { default as notificationsMqQz3wpp0sMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/notifications.vue?macro=true";
import { default as become_45sellersI6ump9t0aMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/partners/become-seller.vue?macro=true";
import { default as indexwMeS2n67SgMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/partners/index.vue?macro=true";
import { default as _91hash_931lgRYiTcOsMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/password-reset/[hash].vue?macro=true";
import { default as change_45emailcAw6PLP5X7Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-email.vue?macro=true";
import { default as change_45name27FoAiLroFMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-name.vue?macro=true";
import { default as change_45phonegBs3lDmgEHMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-phone.vue?macro=true";
import { default as indexfDzw5m4X88Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/index.vue?macro=true";
import { default as registerGBaFG5I6miMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/register.vue?macro=true";
import { default as requestky05NOJUC7Meta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/request.vue?macro=true";
import { default as indexP9q3i83eCzMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/shop/[id]/index.vue?macro=true";
import { default as ratingWnMMU9YeIKMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/shop/[id]/rating.vue?macro=true";
import { default as sockets9Q67YamPATMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/sockets.vue?macro=true";
import { default as indexhoS2IIHOLUMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/support/index.vue?macro=true";
import { default as testYwP2lLlubRMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/test.vue?macro=true";
import { default as indexJRXpYOz2RbMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/index.vue?macro=true";
import { default as infoo9EmPlkt0zMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/info.vue?macro=true";
import { default as transactionsERLdx5tByPMeta } from "/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/transactions.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexd4zF6pPbmWMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-my-cars-id",
    path: "/dashboard/my-cars/:id()",
    meta: _91id_93VakHo7WuGuMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/[id].vue").then(m => m.default || m)
  },
  {
    name: "dashboard-my-cars-add",
    path: "/dashboard/my-cars/add",
    meta: addMSN2LSPB53Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/add.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-my-cars",
    path: "/dashboard/my-cars",
    meta: index1hGeMIbunhMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/my-cars/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-orders",
    path: "/dashboard/orders",
    meta: ordersAGKFQtCiMTMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/orders.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings-change-password",
    path: "/dashboard/settings/change-password",
    meta: change_45password3BMwW1Rj5oMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/settings/change-password.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: indexGLHdBCEZT7Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/dashboard/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "filters",
    path: "/filters",
    meta: filtersNvwGOQfloJMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/filters.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordbGd9IuBjSVMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginbvAlbGdtpbMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-car",
    path: "/my-requests/:id()/car",
    meta: carDBPCfEuE2fMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/car.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-edit",
    path: "/my-requests/:id()/edit",
    meta: editBMiqp3PfKjMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id",
    path: "/my-requests/:id()",
    meta: indexugWTDakyfoMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-request-offer-requestofferid-confirm-request",
    path: "/my-requests/:id()/request-offer/:requestofferid()/confirm-request",
    meta: confirm_45requestVO7S2Uf000Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[request-offer-id]/confirm-request.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-request-offer-requestofferid",
    path: "/my-requests/:id()/request-offer/:requestofferid()",
    meta: index0SsVg0OCNrMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/request-offer/[request-offer-id]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-requests-id-send-review",
    path: "/my-requests/:id()/send-review",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/[id]/send-review.vue").then(m => m.default || m)
  },
  {
    name: "my-requests",
    path: "/my-requests",
    meta: index2B2rlRDK35Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-about",
    path: "/my-shop/change-about",
    meta: change_45aboutj02e60aVhUMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-about.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-address",
    path: "/my-shop/change-address",
    meta: change_45address5ftmqPumQoMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-address.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-email",
    path: "/my-shop/change-email",
    meta: change_45emailCzZZ406sWMMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-email.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-name",
    path: "/my-shop/change-name",
    meta: change_45nameSajP3f4iDVMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-name.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-phone",
    path: "/my-shop/change-phone",
    meta: change_45phoneF0BaOtwgiwMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-phone.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change-website",
    path: "/my-shop/change-website",
    meta: change_45websiteDmHkMMkkyEMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change-website.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-change",
    path: "/my-shop/change",
    meta: changeCcTL14ZU4DMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/change.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-custom-delivery-delivery",
    path: "/my-shop/custom-delivery/:delivery()",
    meta: _91delivery_93RHoI96gC67Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-delivery/[delivery].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-custom-delivery-create",
    path: "/my-shop/custom-delivery/create",
    meta: createsmpsW5GEA1Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-delivery/create.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-custom-payment-method-payment",
    path: "/my-shop/custom-payment-method/:payment()",
    meta: _91payment_939LfOw7Flz8Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-payment-method/[payment].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-custom-payment-method-create",
    path: "/my-shop/custom-payment-method/create",
    meta: createvO6TBGABvqMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/custom-payment-method/create.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-delivery-delivery",
    path: "/my-shop/delivery/:delivery()",
    meta: _91delivery_93C9K1AOgiePMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/delivery/[delivery].vue").then(m => m.default || m)
  },
  {
    name: "my-shop",
    path: "/my-shop",
    meta: indexQdJRREzt83Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-my-requests-sellerrequestid",
    path: "/my-shop/my-requests/:sellerrequestid()",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[seller-request-id]/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-my-requests-sellerrequestid-send-offer",
    path: "/my-shop/my-requests/:sellerrequestid()/send-offer",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[seller-request-id]/send-offer.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-my-requests-sellerrequestid-send-review",
    path: "/my-shop/my-requests/:sellerrequestid()/send-review",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/[seller-request-id]/send-review.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-my-requests",
    path: "/my-shop/my-requests",
    meta: index2wsHnC3NQSMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/my-requests/index.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-payment-method-payment",
    path: "/my-shop/payment-method/:payment()",
    meta: _91payment_93mcHzIJEeQRMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/payment-method/[payment].vue").then(m => m.default || m)
  },
  {
    name: "my-shop-response",
    path: "/my-shop/response",
    meta: responseEibv5v2ENSMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/response.vue").then(m => m.default || m)
  },
  {
    name: "my-shop-settings",
    path: "/my-shop/settings",
    meta: settings0I2jNIFcEJMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/my-shop/settings.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: notificationsMqQz3wpp0sMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "partners-become-seller",
    path: "/partners/become-seller",
    meta: become_45sellersI6ump9t0aMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/partners/become-seller.vue").then(m => m.default || m)
  },
  {
    name: "partners",
    path: "/partners",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-hash",
    path: "/password-reset/:hash()",
    meta: _91hash_931lgRYiTcOsMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/password-reset/[hash].vue").then(m => m.default || m)
  },
  {
    name: "profile-change-email",
    path: "/profile/change-email",
    meta: change_45emailcAw6PLP5X7Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-email.vue").then(m => m.default || m)
  },
  {
    name: "profile-change-name",
    path: "/profile/change-name",
    meta: change_45name27FoAiLroFMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-name.vue").then(m => m.default || m)
  },
  {
    name: "profile-change-phone",
    path: "/profile/change-phone",
    meta: change_45phonegBs3lDmgEHMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/change-phone.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexfDzw5m4X88Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerGBaFG5I6miMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "request",
    path: "/request",
    meta: requestky05NOJUC7Meta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/request.vue").then(m => m.default || m)
  },
  {
    name: "shop-id",
    path: "/shop/:id()",
    meta: indexP9q3i83eCzMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/shop/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-id-rating",
    path: "/shop/:id()/rating",
    meta: ratingWnMMU9YeIKMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/shop/[id]/rating.vue").then(m => m.default || m)
  },
  {
    name: "sockets",
    path: "/sockets",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/sockets.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "wallet",
    path: "/wallet",
    meta: indexJRXpYOz2RbMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/index.vue").then(m => m.default || m)
  },
  {
    name: "wallet-info",
    path: "/wallet/info",
    meta: infoo9EmPlkt0zMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/info.vue").then(m => m.default || m)
  },
  {
    name: "wallet-transactions",
    path: "/wallet/transactions",
    meta: transactionsERLdx5tByPMeta || {},
    component: () => import("/var/www/carpot_com_u_usr/data/www/carpot.com.ua/pages/wallet/transactions.vue").then(m => m.default || m)
  }
]